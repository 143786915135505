<template>
  <div class="convertibleDetail">
    <!-- <Icon type="ios-redo"
          class="goback"
        @click="back" />-->
    <h1>{{ kzz.zqjc }}({{ kzz.zqdm }})</h1>
    <div>
      <div class="table-title">基本资料</div>
      <cTableItem
        :titleArr="['债券代码', '债券简称']"
        :contentArr="[kzz.zqdm, kzz.zqjc]"
      ></cTableItem>
      <cTableItem
        :titleArr="['申购代码', '申购简称']"
        :contentArr="[kzz.sgdm, kzz.sgjc]"
      ></cTableItem>
      <cTableItem
        :titleArr="['原股东配售认购代码', '原股东配售认购简称']"
        :contentArr="[kzz.ygdpsrgdm, kzz.ygdpsrgjc]"
      ></cTableItem>
      <cTableItem
        :titleArr="['原股东股权登记日', '原股东每股配售额（元/股）']"
        :contentArr="[kzz.ygdgqdjr, kzz.ygdmgpse]"
      ></cTableItem>
      <cTableItem
        :titleArr="['正股代码', '正股简称']"
        :contentArr="[kzz.zgdm, kzz.zgjc]"
      ></cTableItem>
      <cTableItem
        :titleArr="['发行价格（元）', '实际募集资金总额（亿元）']"
        :contentArr="[kzz.fxjg, kzz.zqfxze]"
      ></cTableItem>
      <cTableItem
        :titleArr="['申购日期', '申购上限（万元）']"
        :contentArr="[kzz.sgrq, kzz.sgsx]"
      ></cTableItem>
      <cTableItem
        :titleArr="['发行对象']"
        type="one"
        :contentArr="[kzz.fxdx]"
      ></cTableItem>
      <cTableItem
        :titleArr="['发行类型']"
        type="one"
        :contentArr="[kzz.fxlx]"
      ></cTableItem>
      <cTableItem
        :titleArr="['发行备注']"
        type="one"
        :contentArr="[kzz.fxbz]"
      ></cTableItem>
    </div>
    <div style="margin-top: 20px">
      <div class="table-title">债券转换信息</div>
      <cTableItem
        :titleArr="['正股价（元）', '正股市净率']"
        :contentArr="[kzz.zgj, kzz.zgsjl]"
      ></cTableItem>
      <cTableItem
        :titleArr="['债券现价（元）', '转股价（元）']"
        :contentArr="[kzz.zqxj, kzz.zgjj]"
      ></cTableItem>
      <cTableItem
        :titleArr="['转股价值', '转股溢价率']"
        :contentArr="[kzz.zgjz, kzz.zgyjl]"
      ></cTableItem>
      <cTableItem
        :titleArr="['回售触发价', '强赎触发价']"
        :contentArr="[kzz.hscfj, kzz.qscfj]"
      ></cTableItem>
      <cTableItem
        :titleArr="['转股开始日', '转股结束日']"
        :contentArr="[kzz.zgksr, kzz.zgjsr]"
      ></cTableItem>
      <cTableItem
        :titleArr="['最新赎回执行日', '赎回价格（元）']"
        :contentArr="[kzz.zxshzxr, kzz.shjg]"
      ></cTableItem>
      <cTableItem
        :titleArr="['赎回登记日', '最新赎回公告日']"
        :contentArr="[kzz.shdjr, kzz.zxshggr]"
      ></cTableItem>
      <cTableItem
        :titleArr="['赎回原因', '赎回类型']"
        :contentArr="[kzz.shyy, kzz.shlx]"
      ></cTableItem>
      <cTableItem
        :titleArr="['最新回售执行日', '最新回售截止日']"
        :contentArr="[kzz.zxhszxr, kzz.zxhsjzr]"
      ></cTableItem>
      <cTableItem
        :titleArr="['回售价格（元）', '最新回售公告日']"
        :contentArr="[kzz.hsjg, kzz.zxhsggr]"
      ></cTableItem>
      <cTableItem
        :titleArr="['回售原因', '']"
        type="one"
        :contentArr="[kzz.hsyy, '']"
      ></cTableItem>
    </div>
    <div style="margin-top: 20px">
      <div class="table-title">债券信息</div>
      <cTableItem
        :titleArr="['发行价格（元）', '债券发行总额（亿）']"
        :contentArr="[kzz.fxjg, kzz.zqfxze]"
      ></cTableItem>
      <cTableItem
        :titleArr="['债券年度', '债券期限（年）']"
        :contentArr="[kzz.zqnd, kzz.zqqx]"
      ></cTableItem>
      <cTableItem
        :titleArr="['信用级别', '评级机构']"
        :contentArr="[kzz.xyjb, kzz.pjjg]"
      ></cTableItem>
      <cTableItem
        :titleArr="['上市日', '退市日']"
        :contentArr="[kzz.ssr, kzz.tsr]"
      ></cTableItem>
      <cTableItem
        :titleArr="['起息日', '止息日']"
        :contentArr="[kzz.qxr, kzz.zxr]"
      ></cTableItem>
      <cTableItem
        :titleArr="['到期日', '每年付息日']"
        :contentArr="[kzz.dqr, kzz.mnfxr]"
      ></cTableItem>
      <cTableItem
        :titleArr="['利率说明']"
        type="one"
        :contentArr="[kzz.llsm]"
      ></cTableItem>
    </div>
    <div style="margin-top: 20px">
      <div class="table-title">申购状况</div>
      <cTableItem
        :titleArr="['申购日期', '中签号公布日期']"
        :contentArr="[kzz.sgrq, kzz.zqhgbrq ? kzz.zqhgbrq.substr(0, 10) : '']"
      ></cTableItem>
      <cTableItem
        :titleArr="['上市日期', '网上发行中签率（%）']"
        :contentArr="[kzz.ssrq ? kzz.ssrq.substr(0, 10) : '', kzz.wsfxzql]"
      ></cTableItem>
    </div>
    <div style="margin-top: 20px">
      <div class="table-title">中签号</div>
      <cTableItem
        :titleArr="['末“四”位数', '末“六”位数']"
        :contentArr="[kzz.msws, kzz.mlws]"
      ></cTableItem>
      <cTableItem
        :titleArr="['末“七”位数', '末“八”位数']"
        :contentArr="[kzz.mqws, kzz.mbws]"
      ></cTableItem>
      <cTableItem
        :titleArr="['末“九”位数', '']"
        :contentArr="[kzz.mjws, '']"
      ></cTableItem>
    </div>
    <div style="margin-top: 20px">
      <div class="table-title">筹资用途</div>
      <div>
        <div class="line-title">序号</div>
        <div class="line-title">项目名称</div>
        <div class="line-title">计划投资总额（万元）</div>
        <div class="line-title">计划投入募集资金(万元)</div>
      </div>
      <div v-for="(item, index) in kzz.czytList" :key="index">
        <div
          class="line-content"
          style="border-left: 1px solid rgba(214, 215, 217, 1)"
        >
          {{ index + 1 }}
        </div>
        <div class="line-content">
          <Tooltip
            theme="light"
            :content="item.xmmc"
            max-width="300"
            style="width: 100%"
            word-wrap
            placement="top"
            >{{ item.xmmc }}</Tooltip
          >
        </div>
        <div class="line-content">
          <Tooltip
            theme="light"
            :content="item.jhtzze"
            max-width="300"
            style="width: 100%"
            word-wrap
            placement="top"
            >{{ item.jhtzze }}</Tooltip
          >
        </div>
        <div class="line-content">
          <Tooltip
            theme="light"
            :content="item.jhtrmjzj"
            max-width="300"
            style="width: 100%"
            word-wrap
            placement="top"
            >{{ item.jhtrmjzj }}</Tooltip
          >
        </div>
      </div>
    </div>
    <div style="margin-top: 20px">
      <div class="table-title">重要日期</div>
      <div>
        <div class="line-title" style="width: 551px">序号</div>
        <div class="line-title" style="width: 551px">日期</div>
        <div class="line-title" style="width: 551px">安排</div>
      </div>
      <div v-for="(item, index) in kzz.zyrqList" :key="index">
        <div class="line-title" style="width: 551px">{{ index + 1 }}</div>
        <div class="line-title" style="width: 551px">
          {{ item.rq && item.rq.substr(0, 10) }}
        </div>
        <div class="line-title" style="width: 551px">{{ item.ap }}</div>
      </div>
    </div>
    <div style="margin-top: 20px; margin-bottom: 100px">
      <div class="table-title">原股东优先配售股权登记日</div>
      <cTableItem
        :titleArr="['回售条款']"
        type="one"
        :contentArr="[kzz.hstk]"
      ></cTableItem>
      <cTableItem
        :titleArr="['赎回条款']"
        type="one"
        :contentArr="[kzz.shtk]"
      ></cTableItem>
      <!-- <div>
        <div class="line-title">数据来源</div>
        <div class="line-content">
          <Tooltip
                            theme="light" :content="kzz.sjly"
                   max-width="300"
                   style="width:100%"
                   word-wrap
                   placement="top">{{kzz.sjly}}
          </Tooltip>
        </div>
        <div class="line-title">URL</div>
        <div class="line-content"><a target="_blank"
             :href="kzz.url">{{kzz.url}}</a></div>
            </div>-->
    </div>
  </div>
</template>

<script>
import { queryKzzById } from "@/api/financial/debt";
import cTableItem from "@/components/cTableItem";
import { returnFloat } from "@/utils";
export default {
  name: "convertibleDetail",
  data() {
    return {
      kzz: {},
    };
  },
  components: {
    cTableItem,
  },
  mounted() {
    this.getKzz();
  },
  methods: {
    getKzz() {
      queryKzzById({
        id: this.$route.query.id,
      }).then((data) => {
        let arrTo2 = [
          "ygdmgpse",
          "fxjg",
          "zqfxze",
          "sgsx",
          "zgj",
          "zgsjl",
          "zqxj",
          "zgjj",
          "zgjz",
          "hscfj",
          "qscfj",
          "shjg",
          "hsjg",
          "fxjg",
          "zqfxze",
          "wsfxzql",
          "jhtzze",
          "jhtrmjzj",
        ];
        arrTo2.forEach((i) => {
          if (!isNaN(data[i]) && data[i]) {
            data[i] = returnFloat(data[i], 2);

          }
          if(!isNaN(data.czytList[0][i]) && data.czytList[0][i]){
            data.czytList[0][i] = returnFloat(data.czytList[0][i] , 2)
          }
        });
        data.zgyjl = Number(data.zgyjl).toFixed(2) + '%'
        this.kzz = data;
      });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";
.convertibleDetail {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 20px;
  position: relative;
  .goback {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #00b6ff;
    font-size: 36px;
    transform: rotateY(180deg);
    cursor: pointer;
  }
  h1 {
    font-size: 20px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 26px;
    margin-bottom: 20px;
  }
  div.more {
    height: 40px;
    background: rgba(250, 250, 253, 1);
    color: #00b6ff;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
  }
  div {
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    div.table-title {
      @include flex(flex-start);
      padding-left: 20px;
      background: rgba(231, 240, 252, 1);
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      height: 40px;
      line-height: 40px;
      border: 1px solid rgba(214, 215, 217, 1);
    }
    div {
      @include flex;
      height: 40px;
      line-height: 40px;
      .line-title:nth-of-type(1) {
        border-left: 1px solid rgba(214, 215, 217, 1);
      }
      .line-title {
        background: rgba(250, 250, 253, 1);
        width: 414px;
        border-right: 1px solid rgba(214, 215, 217, 1);
        border-bottom: 1px solid rgba(214, 215, 217, 1);
      }
      .line-content {
        background: #fff;
        width: 414px;
        border-right: 1px solid rgba(214, 215, 217, 1);
        border-bottom: 1px solid rgba(214, 215, 217, 1);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
    }
  }
}
</style>
